import React from "react";
import Section from "../Section";
import { Link } from "react-router-dom";
import { SocialIcon } from "react-social-icons";

const Index = () => {
  return (
    <div className="lg:mx-[165px]">
      <div className="flex justify-between items-center p-2 md:p-5">
        <p className="uppercase font-semibold">Andrea La Malfa</p>
        <div className="flex items-center gap-2">
          <Link to="/about" className="mr-3">
            Chi sono
          </Link>
          <SocialIcon
            url="https://www.facebook.com/andrea.lamalfa.75 "
            bgColor="gray"
            style={{ height: 38, width: 38 }}
          />
          <SocialIcon
            url="https://www.instagram.com/a.lamalfa/"
            bgColor="gray"
            style={{ height: 38, width: 38 }}
          />
        </div>
      </div>
      <div className="p-2 md:p-5 flex flex-col items-center">
        <div className="h-16 w-full mt-[40px] md:mt-[83px] bg-[#ff7100]"></div>
        <p className="title text-center mt-5">Il futuro che c'è.</p>
        <p className="text text-center md:w-1/2 mt-[23px]">
          Tutto è possibile se proviamo a farlo insieme. <br />
          Questa campagna elettorale è l’occasione per continuare insieme a
          costruire un Trentino più inclusivo, più attento al benessere delle
          persone, all’economia civile, alla transizione ecologica.
        </p>
        <div className="h-16 w-full mt-5 bg-[#55b9d1]"></div>
        <a href="#rappresentanza">
          <button className="button-full mt-[20px]">Scopri il programma</button>
        </a>
      </div>
      <div className="p-2 md:p-5 flex flex-col md:flex-row items-end">
        <div className="w-full md:w-3/5 p-2 md:p-5">
          <div className="border border-gray-300 rounded-xl p-8 md:p-[57px]">
            <p className="title-secondary">
              Andrea La Malfa, dal volontariato alla rappresentanza.
            </p>
            <p className="text pt-[24px] md:w-2/3">
              Sono nato a Milazzo nel 1985 e vivo a Trento, dove mi sono
              laureato in Sociologia. Sono stato portavoce del Forum trentino
              del terzo settore e sono presidente dell’Arci del Trentino.
            </p>
            <img src="./foto.jpg" className="my-5" />
            <Link to="/about">
              <button className="button-outline mt-10">Scopri chi sono</button>
            </Link>
          </div>
        </div>
        <div className="w-full md:w-2/5 p-2 md:p-5">
          <div className="flex justify-end">
            <img src="./andrea-busto.png" className="hidden" />
          </div>
          <div className="border border-gray-300 rounded-xl p-8 md:p-[57px]">
            <p className="title-secondary">Sostenerci è semplice.</p>
            <p className="text pt-[24px]">
              La politica è tale solo se è azione collettiva. Se vogliamo un
              cambiamento vero e duraturo, abbiamo bisogno di farlo insieme. Non
              è semplice come schiacciare un interruttore; ci può volere tempo,
              impegno, ma ne vale la pena.
            </p>
            <img src="../esempio-voto.png" className="mt-[118px]" />
          </div>
        </div>
      </div>
      <a name="rappresentanza"></a>
      <div className="bg-[#DF1515] text-white flex flex-col items-center py-[76px] md:-mx-[165px]">
        <p className="title-tag uppercase">cosa rappresentiamo</p>
        <p className="title text-center mt-[24px]">
          Non possiamo aspettare il <br /> cambiamento, è tempo di realizzarlo.
        </p>
        <p className="text text-center md:w-1/2 mt-[23px]">
          Negli ultimi cinque anni la qualità della vita in Provincia è
          peggiorata ma per cambiare non basta sottolineare quello che non è
          stato fatto o è stato fatto male.
        </p>
      </div>
      <div className="flex flex-col mx-5 gap-[47px] pt-[74px]">
        <Section
          title="L’associazionismo è stare insieme per il bene comune"
          principal="Le cause della crisi dell’associazionismo e del volontariato sono molteplici. In primo luogo il diverso rapporto tra lavoro e tempo libero, l’instabilità di vita dei giovani, ma anche una sempre crescente tendenza alla burocratizzazione. Pensiamo alla riforma del terzo settore, che non ha adempimenti particolarmente semplificati per le piccole associazioni, e al cosiddetto “decreto Gabrielli” sulla sicurezza degli eventi. Se la Provincia non può intervenire sulle norme nazionali, può però certamente rafforzare i servizi di supporto e formazione dedicati all’associazionismo. "
          secondary="Questo si fa rafforzando il rapporto di collaborazione con le Federazioni e con il Centro Servizi Volontariato, cogliendo la sfida di Trento Capitale Europea del Volontariato come grande occasione di riconoscimento culturale del ruolo dell’associazionismo, investendo nei percorsi di cittadinanza attiva per i giovani. Infine è necessario riscrivere la legge provinciale sul volontariato, il cui impianto originario è del 1992."
        />
        <Section
          title="La cultura come professione
      per migliorarne l’accesso "
          principal="E’ necessario incentivare la crescita del professionismo culturale. E’ un ambito di lavoro rivolto soprattutto alle giovani generazioni, che segue un forte trend in tutta Europa, così come nel vicino Alto Adige."
          secondary="Il professionismo culturale è chiamato a rafforzare le competenze diffuse, creare lavoro per le giovani generazioni e migliorare l’offerta culturale, turistica e di servizi del Trentino. Per questo motivo proponiamo un fondo specifico e bandi pluriennali aperti ad imprese sociali a carattere culturale e cooperative. Bandi che favoriscano le realtà professionali capaci di creare relazioni significative con associazioni, enti pubblici, enti di promozione turistica, biblioteche e musei per innovare le attività ed i servizi culturali. Accanto a ciò vanno rafforzate le strutture associative capaci di svolgere funzioni pubbliche e creare lavoro, senza che questo diminuisca la capacità di autonomia ed autorganizzazione dei cittadini."
        />
        <Section
          title="Migliori condizioni di lavoro
      per una società più giusta"
          principal="Lavorare ha spesso un coinvolgimento sulla nostra identità. Le condizioni di lavoro però negli anni sono peggiorate, in particolar modo in termini di retribuzione."
          secondary="La Provincia è in Trentino il primo attore nel campo del lavoro. Negli anni ha delegato molti servizi all’esterno e al terzo settore (non solo socio sanitario, ma anche biblioteche, cura dell’infanzia e adolescenza). La flessibilità e la capacità di sperimentare di questi enti hanno garantito il miglioramento di molti servizi, anche a fronte di risparmi economici. Questo meccanismo ha però ormai superato il limite, intaccando le retribuzioni e le condizioni lavorative degli addetti. Dato che negli enti si arriva a corrispondere il 90% dei bilanci in stipendi dei dipendenti, è evidente come sia necessario partire dal fornitore pubblico. Nei bandi di gara e nelle procedure di coprogettazione le ore di lavoro ipotizzate e richieste devono essere esplicitate e calcolate in maniera non comprimibile con il costo orario del contratto collettivo di riferimento. Le attività lavorative che intervengono sulla conciliazione dei tempi di vita (come le reperibilità e le notti, anche “passive”) devono trovare un equo riconoscimento economico. Nel settore privato va incentivata la crescita della dimensione del tessuto produttivo: condizione necessaria per migliorare le condizioni di lavoro dei dipendenti, che devono essere sempre di più coinvolti nella gestione seguendo il modello tedesco. "
        />
        <Section
          title="La casa
      è un punto di partenza"
          principal="Giovani coppie, separati, migranti, lavoratori poveri, universitari: sono molte le persone per cui l’abitare oggi rappresenta un problema. L'esplosione dei prezzi di acquisto e di affitto delle case, soprattutto a Trento, richiede nuovi e forti politiche abitative."
          secondary="Nei cinque anni della Giunta uscente, gli appartamenti ITEA sfitti sono praticamente raddoppiati, superando le mille unità. Secondo gli ultimi dati disponibili (2020) su quasi tremila domande, ITEA ha assegnato poco più di cento alloggi, meno del 5%.
      Bastano questi numeri a certificare il fallimento della gestione dell’ente case popolari. E’ stata così creata una situazione di emergenza da cui sarà difficile, ma necessario, uscire. ITEA dovrà mettere in atto un piano straordinario per la riqualificazione e l’assegnazione del proprio patrimonio immobiliare. Ciò non sarà però sufficiente: vanno diversificati gli interventi. Per ridurre la domanda di alloggi studenteschi sul mercato privato va potenziato il ruolo dell’Opera Universitaria. Così come va ampliata la capacità di azione del privato sociale, sia tramite il rafforzamento del cohousing, sia tramite progetti che forniscano garanzie ed affitti calmierati ai soggetti più svantaggiati dal mercato. "
        />
        <Section
          title="La salute e la cura sono diritti
      garantiamoli per tutti"
          principal="La sanità trentina è peggiorata. Tempi sempre più lunghi per ottenere esami e visite specialistiche. Per chi, farlo a pagamento è troppo spesso l’unico modo per averla in tempi ragionevoli. E per chi non può? Nel 2018 meno di un trentino su venti rinunciava alle cure per problemi economici o di difficoltà di accesso. Nel 2021 sono uno su dieci. Dobbiamo invertire la rotta. Chi ha bisogno di cure deve trovare le risposte che cerca. "
          secondary="La Provincia deve tornare ad investire nel sistema sanitario pubblico ed universalistico, una delle più grandi conquiste da difendere. Di fronte alla malattia non ci può esserci differenza tra ricco e povero: ognuno ha diritto di essere curato nel miglior modo possibile. Vanno ridotte le liste d’attesa con un reale monitoraggio dell’accesso alle cure, che tenga conto della gravità della condizione di salute. 
      Ma la salute non è solo assenza di malattia, ma soprattutto un benessere fisico, mentale e sociale. L’integrazione dei servizi sanitari con il sociale è un punto cardine. C’è bisogno di una maggiore attenzione verso la salute mentale, guardata troppo spesso ancora come uno stigma. Bisogna inoltre puntare sui servizi di prossimità e di medicina territoriale, il vero centro in cui si può prevenire e diagnosticare precocemente la malattia e, in caso di cronicità, garantire servizi a bassa soglia per accompagnare le persone oltre la fase acuta."
        />
        <Section
          title="La tutela dell’ambiente
      è la tutela di noi stessi"
          principal="Mai come oggi ci rendiamo conto che tutelare l’ambiente significa anche tutelare noi stessi. I cambiamenti climatici sono evidenti, anche nel delicato ecosistema alpino delle montagne trentine. La riduzione dell’impatto ambientale e la compensazione delle azioni umane devono diventare chiavi trasversali negli ambiti di azione politica ed amministrativa. "
          secondary="Ciò significa che la mobilità deve puntare meno sull’auto privata e sulle strade per investire sui mezzi di trasporto pubblici e sulla mobilità leggera. Manca ancora oggi un piano provinciale della mobilità, necessario a dare una prospettiva coerente. A parità di spesa pubblica tra l’altro l’investimento in trasporto pubblico genera nuovi posti di lavoro e una migliore qualità della vita. Inoltre bisognerà sempre più spingere come azione culturale, i grandi eventi ed istituzioni ad impegnarsi in azioni di compensazione ambientale della propria attività. 
      Dobbiamo in pratica e nella realtà realizzare anche in Trentino un Green New Deal, investendo nella transizione ecologica in modo prioritario e trasversale, facendo in modo che la transizione ecologica sia socialmente equa e non diventi l’occasione di nuove disuguaglianze sociali. "
        />
        <Section
          title="La lotta contro il potere mafioso è il momento di reagire"
          principal="E’ una tragica realtà che non possiamo sottacere: la mafia in Trentino esiste. Lo certifica la sentenza Perfido, che rischia di essere la punta dell'iceberg. La lotta alla mafia va inserita nell’agenda politica, bisogna parlarne ed è necessario agire insieme. "
          secondary="La lotta al potere mafioso deve servirsi di molti strumenti. Di una stampa libera, che non riporti solamente le notizie, ma che sappia fare giornalismo d’inchiesta; di una cittadinanza attiva che non lasci la gestione della cosa pubblica ai pochi; di un’imprenditoria attenta e consapevole; di forze dell’ordine e di una la magistratura che possa svolgere le indagini e le inchieste utili a far emergere i reati e le collusioni. E’ un impegno che la comunità e la politica devono assumersi. Anche per fare ciò, la Provincia ha il dovere di istituire un “Osservatorio sul potere mafioso in Trentino”, come punto di raccordo che sappia tenere alta la guardia e lo sguardo          "
        />
      </div>
      <div className="bg-[#DF1515] text-white flex flex-col items-center py-[76px] mt-[50px] md:-mx-[165px]">
        <p className="title-tag uppercase">contattaci</p>

        <p className="text text-center md:w-1/2 mt-[23px]">
          lamalfa.andrea@gmail.com
        </p>
      </div>
    </div>
  );
};

export default Index;
