import React from "react";
import { Link } from "react-router-dom";
import { SocialIcon } from "react-social-icons";

const About = () => {
  return (
    <div className="lg:mx-[165px]">
      <div className="flex justify-between items-center p-2 md:p-5">
        <p className="uppercase font-semibold">Andrea La Malfa</p>
        <div className="flex items-center gap-2">
          <Link to="/" className="mr-3">
            Home
          </Link>
          <SocialIcon
            url="https://www.facebook.com/andrea.lamalfa.75 "
            bgColor="gray"
            style={{ height: 38, width: 38 }}
          />
          <SocialIcon
            url="https://www.instagram.com/a.lamalfa/"
            bgColor="gray"
            style={{ height: 38, width: 38 }}
          />
        </div>
      </div>
      <div className="p-2 md:p-5">
        <div className="border border-gray-300 rounded-xl p-8 md:p-[57px]">
          <p className="title-secondary">Su di me, Andrea La Malfa.</p>
          <p className="text pt-[24px]">
            Sono nato a Milazzo, in Sicilia, nel 1985: mio padre è vigile
            sanitario, mia madre casalinga e ho un fratello di 12 anni più
            grande. I miei nonni materni erano agricoltori nella Tuscia laziale,
            posto dove trascorrevamo le ferie estive e natalizie. I nonni
            paterni erano decisamente più vicini, vivendo nel nostro stesso
            quartiere, dove gestivano una piccola bottega di generi alimentari.
            La mia infanzia è stata scandita dallo studio e dal calcio,
            praticato in strada ed in una delle tante squadre giovanili della
            zona. Alle superiori scelgo il Liceo delle Scienze sociali a
            Castroreale. Circa un’ora e mezza di autobus, rari, stracolmi ed
            inaffidabili, che portano le studentesse ed i pochi studenti alle
            prime proteste. Mi avvicino per questo alla rappresentanza
            studentesca e all’antimafia sociale. La passione per le scienze
            sociali mi porta, conseguita la maturità, a Trento alla facoltà di
            Sociologia, dove dopo la triennale mi laureo in Sociologia del
            Lavoro e dell’Organizzazione. In Università continuo ad impegnarmi
            nella rappresentanza studentesca, occupandomi di diritto allo studio
            all’Opera Universitaria. Un impegno per garantire, anche a chi era
            privo di mezzi, di poter studiare nelle migliori condizioni,
            convinto che una delle maggiori ingiustizie sia che il futuro delle
            persone venga determinato dalle condizioni casuali della nascita.
            Nell’autunno 2008 arriva anche a Trento l’Onda, il movimento
            studentesco che si oppone alla riforma universitaria del Governo
            Berlusconi. Sono quelli gli anni in cui i Democratici di Sinistra, a
            cui mi ero iscritto poco prima, danno vita al Partito Democratico
            del Trentino, ed in cui inizio l’attività di volontariato all’Arci
            con l’allora presidente Ugo Winkler e Wanda Chiodi. Con
            l’associazione organizziamo moltissime iniziative culturali e mostre
            d’arte, avviamo i nuovi circoli ed i progetti rivolti ai giovani,
            come la festa del 25 Aprile, i viaggi della memoria nei campi di
            concentramento e nei luoghi della Resistenza ed i campi estivi nei
            beni confiscati alla mafia. La trasmissione della memoria, la
            cultura popolare, la promozione dell’associazionismo e della
            cittadinanza attiva, in particolar modo tra i giovani, sono gli
            ambiti in cui, con l'Arci del Trentino, mi sono impegnato
            maggiormente. Da volontario divento lavoratore dell’associazione,
            poi segretario e successivamente presidente del comitato trentino e
            componente della presidenza e dell’esecutivo nazionale. In seguito
            alla riforma nazionale del terzo settore del 2007, e sentendo la
            necessità di una maggiore voce del privato sociale, l’Arci del
            Trentino si fa promotrice della ricostituzione del Forum trentino
            del terzo settore, l’articolazione territoriale dell’ente di
            rappresentanza nazionale, di cui sono stato portavoce.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
