import React from "react";
import { useState } from "react";

const Section = ({ title, principal, secondary }) => {
  const [showSection, setShowSection] = useState(false);
  return (
    <div id="card" className="my-5">
      <div id="sezione1">
        <div className="flex flex-col md:flex-row gap-5 justify-between md:items-center">
          <p className="title-secondary">{title}</p>
          <button
            className={`${showSection ? "button-full" : "button-outline"}`}
            onClick={() => setShowSection(!showSection)}
          >
            {showSection ? "Chiudi" : "Approfondisci"}
          </button>
        </div>
        <hr className="my-5" />
        <p className="text">{principal}</p>
      </div>
      <div
        id="sezione2"
        className={`${showSection ? "block" : "hidden"}  text mt-5`}
      >
        {secondary}
      </div>
    </div>
  );
};

export default Section;
